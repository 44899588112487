@import "styles/components";
@import "styles/utils";

.header {
  @include typography(50px, 56px);
  text-align: center;
  margin: 30px 0;

  @include phone() {
    @include typography(2.9rem, 4rem);
    max-width: 80%;
  }

  @include phone-landscape() {
    @include typography(2.9rem);
  }
}